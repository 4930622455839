//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bus } from '@/util/bus'
import { getSelected } from '@/util/util.js'
export default {
  name: 'TreeList',
  props: {
    treeData: {
      type: Object,
      default: () => {}
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'label'
        }
      }
    },
    nowChooseClassId: {
      type: Object,
      default: () => {
        return {
          id: -1
        }
      }
    },
    first: {
      type: Boolean,
      default: false
    },
    // 点击时存储被点击的项  数据
    clickGroupItem: {
      type: Object,
      default: () => null
    },
    prevClickItem: {
      type: Object,
      default: () => null
    },
    // 是否有添加操作正在进行
    isHaveAdd: {
      type: Boolean,
      default: () => false
    },
    isHaveRename: {
      type: Boolean,
      default: () => false
    },
    // 重命名时需要聚焦的input
    focusInput: {
      type: Object,
      default: () => null
    },
    // 右键菜单的行内样式
    rightMenuStyle: {
      type: Object,
      default: () => {
        return {
          left: '0px',
          top: '0px',
          display: 'none'
        }
      }
    }
  },
  mounted() {
    if (this.first) {
      document.addEventListener('contextmenu', this.rightClick)
      document.addEventListener('click', this.leftClick)
      document.addEventListener('keyup', this.keyEnter)
      bus.$on('changeClickGroupItem', (res) => {
        this.clickGroupItem = res
      })
      bus.$on('changePrevClickItem', (res) => {
        this.prevClickItem = res
      })
      bus.$on('changeIsHaveAdd', (res) => {
        this.isHaveAdd = res
      })
      bus.$on('changeIsHaveRename', (res) => {
        this.isHaveRename = res
      })
      bus.$on('changeFocusInput', (res) => {
        this.focusInput = res
      })
      bus.$on('changeRightMenuStyle', (res) => {
        this.rightMenuStyle = res
      })
    }
  },
  beforeDestroy() {
    if (this.first) {
      document.removeEventListener('contextmenu', this.rightClick)
      document.removeEventListener('click', this.leftClick)
      document.removeEventListener('keyup', this.keyEnter)
      bus.$off([
        'changeClickGroupItem',
        'changeIsHaveAdd',
        'changeIsHaveRename',
        'changeFocusInput',
        'changeRightMenuStyle',
        'changePrevClickItem'
      ])
    }
  },
  methods: {
    keyEnter($event) {
      if ($event.keyCode == 13) {
        this.clearAll()
      }
    },
    rightClick($event) {
      $event.preventDefault()
      if ($event.target.className != 'itemLabel') {
        this.clearAll()
      }
    },
    leftClick() {
      let res = getSelected()
      if (!res) {
        this.clearAll()
      }
    },
    // 清空所有输入框和中间状态
    clearAll() {
      this.closeRightMenu()
      if (!this.isHaveAdd && !this.isHaveRename) return
      let item = null
      if (this.isHaveAdd || this.isHaveRename) {
        item = this.prevClickItem
      } else {
        item = this.clickGroupItem
      }
      // 检测是否在新增分类
      if (this.isHaveAdd) {
        let addIndex = item[this.defaultProps.children].length - 1
        let addItem = item[this.defaultProps.children][addIndex]
        if (addItem.updateName == '') {
          addItem.updateName = '新建文件夹'
        }
        this.$axios
          .post(this.$api.sourceCenter.ImagesGroupAdd, {
            name: addItem.updateName,
            pid: item.id
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success('添加成功')
              item.updateNameFlag = false
              item[this.defaultProps.children].pop()
              item.sonIsOpen = true
              if (item[this.defaultProps.children]) {
                item[this.defaultProps.children].push({
                  id: res.result,
                  name: addItem.updateName,
                  updateNameFlag: false,
                  updateName: '',
                  pid: item.id,
                  sonIsOpen: false,
                  checked: false
                })
              } else {
                let arr = [
                  {
                    id: res.result,
                    name: addItem.updateName,
                    updateNameFlag: false,
                    updateName: '',
                    pid: item.id,
                    sonIsOpen: false,
                    checked: false
                  }
                ]
                this.$set(item, `${this.defaultProps.children}`, arr)
              }
              this.successAddGroup(item.id, res.result, addItem.updateName)
              this.clearStorage()
            } else {
              if (item[this.defaultProps.children] && item[this.defaultProps.children].length > 1) {
                item[this.defaultProps.children].splice(addIndex, 1)
              } else {
                delete item[this.defaultProps.children]
              }
              this.clearStorage()
              this.$message.error(res.msg)
            }
          })
      }
      // 检测是不是重命名
      if (this.isHaveRename) {
        if (item.updateName == '') {
          item.updateName = ''
          item.updateNameFlag = false
          this.clearStorage()
          return
        }
        this.$axios
          .post(this.$api.sourceCenter.ImagesGroupEdit, {
            name: item.updateName,
            id: item.id
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success('修改成功')
              item[this.defaultProps.label] = item.updateName
              item.updateName = ''
              item.updateNameFlag = false
              this.reNameThenDeal(item)
              this.clearStorage()
            } else {
              item.updateName = ''
              item.updateNameFlag = false
              this.$message.error(res.msg)
              this.clearStorage()
            }
          })
      }
    },
    // 清空页面缓存的变量
    clearStorage() {
      if (this.first) {
        this.prevClickItem = null
        this.focusInput = null
        this.clickGroupItem = null
        this.isHaveAdd = false
        this.isHaveRename = false
      } else {
        bus.$emit('changeClickGroupItem', null)
        bus.$emit('changePrevClickItem', null)
        bus.$emit('changeIsHaveAdd', false)
        bus.$emit('changeIsHaveRename', false)
        bus.$emit('changeFocusInput', null)
      }
    },
    // 点击箭头打开或关闭子菜单
    openThisNode(item) {
      if (item[this.defaultProps.children]) {
        this.$set(item, 'sonIsOpen', !item.sonIsOpen)
      }
    },
    // 点击节点
    clickNodeLabel($event, item) {
      // 展开或者关闭节点
      if (item && $event.target) {
        this.openThisNode(item)
      }
      if (this.first) {
        this.clearAll()
      }
      this.$emit('clickNodeLabel', { target: null }, item)
    },
    // 重新拉取所有的节点
    getImgClass() {
      this.$emit('getImgClass')
    },
    // 处理删除节点后的细节
    deleteThenDeal(item) {
      this.$emit('deleteDeal', item)
    },
    // 处理重命名节点后的细节
    reNameThenDeal(item) {
      this.$emit('reNameThenDeal', item)
    },
    // 处理添加节点后的细节
    successAddGroup(pid, id, name) {
      this.$emit('successAddGroup', pid, id, name)
    },
    // 点击鼠标右键显示自定义菜单
    rightMenu($event, item) {
      $event.preventDefault()
      if (item.id === -1 || item.id === 0 || item.name == '') {
        this.closeRightMenu()
        return
      }
      let res = $event.target.getBoundingClientRect()
      let obj = {}
      obj.left = res.left + $event.target.clientWidth / 2 + 'px'
      obj.top = res.top + $event.target.clientHeight / 2 + 'px'
      obj.display = 'block'
      if (this.first) {
        this.focusInput = $event.target
        this.clickGroupItem = item
        if (!this.isHaveAdd && !this.isHaveRename) {
          this.prevClickItem = item
        }
        this.rightMenuStyle = obj
      } else {
        bus.$emit('changeClickGroupItem', item)
        if (!this.isHaveAdd && !this.isHaveRename) {
          bus.$emit('changePrevClickItem', item)
        }
        bus.$emit('changeFocusInput', $event.target)
        bus.$emit('changeRightMenuStyle', obj)
      }
    },
    // 关闭右键菜单
    closeRightMenu() {
      if (this.first) {
        this.rightMenuStyle = {
          left: '0px',
          top: '0px',
          display: 'none'
        }
      } else {
        let obj = {
          left: '0px',
          top: '0px',
          display: 'none'
        }
        bus.$emit('changeRightMenuStyle', obj)
      }
    },
    // 删除该分组
    deleteGroup() {
      let that = this
      let item = this.clickGroupItem
      this.$confirm(`此操作将永久删除该文件夹`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.$axios
            .post(that.$api.sourceCenter.ImagesGroupDel, {
              id: [item.id]
            })
            .then((res) => {
              if (res.code === 0) {
                that.$message.success('删除成功')
                this.deleteThenDeal(item)
              }
              this.closeRightMenu()
            })
        })
        .catch(() => {})
    },
    // 重命名该分组
    reName() {
      if (this.isHaveAdd) {
        this.$message.warning('请先完成上一个添加操作')
        this.closeRightMenu()
        return
      }
      if (this.isHaveRename) {
        this.$message.warning('请先完成上一个重命名操作')
        this.closeRightMenu()
        return
      }
      this.clickGroupItem.updateNameFlag = true
      this.$nextTick(() => {
        this.focusInput.nextElementSibling.focus()
      })
      if (this.first) {
        this.isHaveRename = true
      } else {
        bus.$emit('changeIsHaveRename', true)
      }
      this.closeRightMenu()
    },
    // 添加新的分组
    addNewGroup() {
      let obj = {}
      obj.updateNameFlag = true
      obj.updateName = ''
      obj.name = ''
      if (this.isHaveAdd) {
        this.$message.warning('请先完成上一个添加操作')
        this.closeRightMenu()
        return
      }
      if (this.isHaveRename) {
        this.$message.warning('请先完成上一个重命名操作')
        this.closeRightMenu()
        return
      }
      if (!this.clickGroupItem[this.defaultProps.children]) {
        this.$set(this.clickGroupItem, this.defaultProps.children, [obj])
      } else {
        this.clickGroupItem[this.defaultProps.children].push(obj)
      }
      if (!this.clickGroupItem.sonIsOpen) {
        this.openThisNode(this.clickGroupItem)
      }
      // 等待dom加载完成
      setTimeout(() => {
        // 聚焦新增文件夹的input
        let inputPrev = this.focusInput.parentElement.parentElement.parentElement.children[1]
          .children
        let index = inputPrev.length - 1
        let thatLable = inputPrev[index].children[0].children[0].children[2]
        thatLable.focus()
      }, 100)
      if (this.first) {
        this.isHaveAdd = true
      } else {
        bus.$emit('changeIsHaveAdd', true)
      }
      this.closeRightMenu()
    }
  }
}
