//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showProgressList: {
      type: Array,
      default: () => []
    },
    groupId: {
      type: Number,
      default: null
    }
  },
  methods: {
    async launchUpload() {
      for (let index = 0; index < this.showProgressList.length; index++) {
        let formData = new FormData()
        formData.append('files', this.showProgressList[index].file)
        // 设置分组
        if (this.groupId != -1 || this.groupId != -2) {
          formData.append('group_id', this.groupId)
        }
        await this.$axios
          .showProgressUpload(this.$api.user.addFiles, formData, (data) => {
            let { loaded, total } = data
            this.showProgressList[index].percentage =
              Math.floor((loaded / total) * 100) - 10 >= 0
                ? Math.floor((loaded / total) * 100) - 10
                : 0
          })
          .then((res) => {
            if (res.code !== 0) {
              console.log(res, 222)
              this.showProgressList[index].error = true
              this.showProgressList[index].errorDetail = res.msg
            } else {
              this.showProgressList[index].percentage = 100
              if (index === this.showProgressList.length - 1) {
                this.$emit('chooseSuccess')
              }
            }
          })
          .catch((err) => {
            console.log(err, 333)
            this.showProgressList[index].error = true
            this.showProgressList[index].errorDetail = '上传失败'
          })
      }
    }
    // uploadFunction(item, formData) {
    //   return new Promise((resolve, reject) => {
    //     this.$axios
    //       .showProgressUpload(this.$api.user.addFiles, formData, (data) => {
    //         let { loaded, total } = data
    //         item.percentage = Math.floor((loaded / total) * 100)
    //       })
    //       .then((res) => {
    //         if (res.code !== 0) {
    //           resolve(res)
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err, 1111)
    //         reject(err)
    //       })
    //   })
    // }
  }
}
