//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getfilesize } from '@/util/util.js'
export default {
  props: {
    showVideoDialog: {
      type: Boolean,
      default: false
    },
    classData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      videoName: '',
      videoUpload: {
        name: null,
        percentage: 0,
        size: null,
        file: null,
        groupClass: [-1],
        cover: null
      },
      definedProps: {
        value: 'id',
        label: 'name',
        children: '_child',
        checkStrictly: true
      }
    }
  },
  methods: {
    videoDialogClose() {
      this.clearUploadList(1)
      this.$emit('videoDialogClose')
    },
    // 获取要上传的视频文件
    getUploadvideo($event) {
      this.clearUploadList(1)
      let files = $event.target.files
      for (const key in files) {
        if (!isNaN(Number(key))) {
          let item = files[key]
          console.log(item)
          let maxSize = 1024 * 1024 * 20
          if (item.size > maxSize) {
            this.$message({
              message: `${item.name}超过最大尺寸，已从上传队列删除`,
              type: 'error',
              customClass: 'mzindex'
            })
            continue
          }
          this.videoUpload.name = item.name
          this.videoUpload.size = getfilesize(item.size)
          this.videoUpload.file = item
        }
      }
    },
    // 获取需要上传的视频封面 => 并上传
    getImgFile($event) {
      let file = $event.target.files[0]
      let formData = new FormData()
      formData.append('files', file)
      this.$axios.uploadFile(this.$api.user.addFiles, formData).then((res) => {
        if (res.code == 0) {
          this.videoUpload.cover = res.result.path
        }
      })
    },
    clearUploadList(status) {
      this.videoUpload.name = null
      this.videoUpload.percentage = 0
      this.videoUpload.size = null
      this.videoUpload.file = null
      this.videoUpload.cover = null
      if (!status) this.$refs.videoInput.value = null
    },
    // 点击开始上传视频
    startUpload() {
      if (!this.videoUpload.size) {
        this.$message({
          message: `请上传视频`,
          type: 'error',
          customClass: 'mzindex'
        })
        return
      }
      if (this.videoUpload.name == '') {
        this.$message({
          message: `视频名称不能为空`,
          type: 'error',
          customClass: 'mzindex'
        })
        return
      }
      if (this.videoUpload.groupClass == null || this.videoUpload.groupClass.length == 0) {
        this.$message({
          message: `请选择视频分类`,
          type: 'error',
          customClass: 'mzindex'
        })
        return
      }
      let formData = new FormData()
      formData.append('files', this.videoUpload.file)
      this.$axios
        .showProgressUpload(this.$api.user.addFiles, formData, (data) => {
          let loaded = JSON.stringify(data.loaded)
          let total = JSON.stringify(data.total)
          this.videoUpload.loaded = getfilesize(data.loaded)
          this.videoUpload.percentage = (Math.floor((loaded / total) * 100) - 10) | 0
        })
        .then((res) => {
          if (res.code == 0) {
            this.videoUpload.percentage = 100
            let obj = {
              id: res.result.id,
              nickname: this.videoUpload.name,
              group_id: this.videoUpload.groupClass[this.videoUpload.groupClass.length - 1]
            }
            if (this.videoUpload.cover) {
              obj.cover = this.videoUpload.cover
            }
            return this.$axios.post(this.$api.set.editVideo, obj)
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              customClass: 'mzindex'
            })
          }
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: `成功上传视频`,
              type: 'success',
              customClass: 'mzindex'
            })
            this.$emit('uploadVideoSuccess')
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              customClass: 'mzindex'
            })
          }
        })
    },
    deleteCover() {
      this.videoUpload.cover = ''
      this.$refs.imgInput.value = null
    }
  }
}
